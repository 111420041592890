import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "toolbar flex align-center justify-space-between" }
const _hoisted_2 = { class: "box-left box grow flex" }
const _hoisted_3 = { class: "box-right flex align-center pl-10" }
const _hoisted_4 = {
  key: 0,
  class: "mdi mdi-fullscreen"
}
const _hoisted_5 = {
  key: 1,
  class: "mdi mdi-fullscreen-exit"
}
const _hoisted_6 = { class: "username" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.menuBurger !== 'right')
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSidebar && _ctx.toggleSidebar(...args))),
            class: "toggle-sidebar card-base card-shadow--small"
          }, _cache[3] || (_cache[3] = [
            _createElementVNode("i", { class: "mdi mdi-menu" }, null, -1)
          ])))
        : _createCommentVNode("", true),
      _cache[4] || (_cache[4] = _createElementVNode("img", {
        class: "logo-mini",
        src: "/logo.svg",
        alt: "logo"
      }, null, -1))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        class: "fullscreen-button",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleFullscreen && _ctx.toggleFullscreen(...args)))
      }, [
        (!_ctx.fullscreen)
          ? (_openBlock(), _createElementBlock("i", _hoisted_4))
          : _createCommentVNode("", true),
        (_ctx.fullscreen)
          ? (_openBlock(), _createElementBlock("i", _hoisted_5))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("span", _hoisted_6, [
        _createVNode(_component_router_link, { to: "" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.loggedUserName), 1)
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_el_dropdown, {
        trigger: "click",
        onCommand: _ctx.onCommand
      }, {
        dropdown: _withCtx(() => _cache[5] || (_cache[5] = [])),
        default: _withCtx(() => [
          _cache[6] || (_cache[6] = _createElementVNode("span", { class: "el-dropdown-link" }, [
            _createElementVNode("img", {
              src: "/avatar.jpg",
              class: "avatar",
              alt: "avatar"
            })
          ], -1))
        ]),
        _: 1
      }, 8, ["onCommand"]),
      (_ctx.menuBurger === 'right')
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleSidebar && _ctx.toggleSidebar(...args))),
            class: "toggle-sidebar toggle-sidebar__right card-base card-shadow--small"
          }, _cache[7] || (_cache[7] = [
            _createElementVNode("i", { class: "mdi mdi-menu" }, null, -1)
          ])))
        : _createCommentVNode("", true)
    ])
  ]))
}