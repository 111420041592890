import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "box grow flex nav-container" }
const _hoisted_2 = {
  class: "flex align-center justify-center",
  style: {"width":"4rem"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_logo = _resolveComponent("logo")!
  const _component_Nav = _resolveComponent("Nav")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["horizontal-nav flex", { ['pos-' + _ctx.position]: true }])
  }, [
    _createVNode(_component_logo, { mode: "horizontal" }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_scrollbar, { class: "box grow" }, {
        default: _withCtx(() => [
          _createVNode(_component_Nav, {
            "navigation-items": _ctx.navItems,
            onPushPage: _ctx.pushPage,
            mode: "horizontal"
          }, null, 8, ["navigation-items", "onPushPage"])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", {
          class: "mdi mdi-logout",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logoutClick && _ctx.logoutClick(...args)))
        })
      ])
    ])
  ], 2))
}