import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "box-nav box grow",
  style: {"padding-top":"40px"}
}
const _hoisted_2 = { class: "flex justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_Nav = _resolveComponent("Nav")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["vertical-nav", { 'nav-collapsed': _ctx.collapseNav, open: _ctx.openSidebar }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["sidebar-mask", { open: _ctx.openSidebar }]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeNav && _ctx.closeNav(...args)))
    }, null, 2),
    _createElementVNode("div", {
      class: _normalizeClass(["sidebar flex column", { open: _ctx.openSidebar, ['pos-' + _ctx.position]: true }])
    }, [
      _createVNode(_component_Logo, {
        "logo-component": _ctx.appLogo,
        style: {"padding-top":"30px"},
        "collapse-nav": _ctx.collapseNav,
        onCollapseNavToggle: _ctx.collapseNavToggle
      }, null, 8, ["logo-component", "collapse-nav", "onCollapseNavToggle"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_scrollbar, { class: "scroll-nav" }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_Nav, {
                "navigation-items": _ctx.navigationItems,
                "is-collapse": _ctx.collapseNav,
                onPushPage: _ctx.pushPage,
                onCollapseNavToggle: _ctx.collapseNavToggle
              }, null, 8, ["navigation-items", "is-collapse", "onPushPage", "onCollapseNavToggle"]),
              _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1))
            ]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_el_button, {
                type: "danger",
                onClick: _ctx.logoutClick,
                plain: ""
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createElementVNode("span", { class: "mdi mdi-logout" }, "Logout", -1)
                ])),
                _: 1
              }, 8, ["onClick"])
            ])
          ]),
          _: 1
        })
      ])
    ], 2)
  ], 2))
}